import React from 'react';
import { AuthContextProvider } from './modules/auth/contexts/AuthContext';
import { MachinesContextProvider } from './modules/machinesAdminManagement/contexts/MachinesContext';
import { CompaniesContextProvider } from './modules/companiesAdminManagement/contexts/CompaniesContext';
import { CompanyAccessContextProvider } from './modules/companiesAdminManagement/contexts/CompanyAccessContext';
import { UserCompaniesContextProvider } from './modules/companiesAccountManagement/contexts/UserCompaniesContext';
import { PermissionGroupsContextProvider } from './modules/permissionGroups/contexts/PermissionGroupsContext';
import { UserUsersContextProvider } from './modules/userUsers/contexts/UsersContext';
import { UserMachinesContextProvider } from './modules/machinesAccountManagement/contexts/UserMachinesContext';
import { UserProfileContextProvider } from './modules/userUsers/contexts/UserProfileContext';
import { UserMachineAccessContextProvider } from './modules/adminUsers/contexts/UserMachineAccessContext';
import { AdminUsersContextProvider } from './modules/adminUsers/contexts/UsersContext';
import { NotificationContextProvider } from './modules/notification/contexts/NotificationContext';
import { AdminAnalyticsContextProvider } from './modules/adminAnalytics/contexts/AdminAnalyticsContext';
import { MachineDashboardContextProvider } from './modules/machineDashboard/contexts/MachineDashboardContext';
import { MachineDeploymentContextProvider } from './modules/machineDeployments/contexts/DeploymentsContext';
import { ApiLogContextProvider } from './modules/apiLogs/contexts/ApiLogsContext';
import { MachineRequestsContextProvider } from './modules/machineRequests/contexts/MachineRequestsContext';
import { MachineCertificatesProvider } from './modules/machineCertificates/contexts/MachineCertificatesContext';
import { MachineRequestFiltersContextProvider } from './modules/machineRequests/contexts/MachineRequestFiltersContext';
import { SoftwareVersionContextProvider } from './modules/softwareVersions/contexts/SoftwareVersionsContext';
import { DatabridgeAdminContextProvider } from './modules/externalApiPartners/contexts/DatabridgeAdminContext';
import { ExternalApiPartnersContextProvider } from './modules/externalApiPartners/contexts/ExternalApiPartnersContext';
import { ManufacturersContextProvider } from './modules/manufacturerAdminManagement/contexts/ManufacturersContext';

const Contexts: React.FC = (props) => {
  const { children } = props;

  return (
    <AuthContextProvider>
      <ManufacturersContextProvider>
        <MachinesContextProvider>
          <MachineCertificatesProvider>
            <MachineDeploymentContextProvider>
              <SoftwareVersionContextProvider>
                <ExternalApiPartnersContextProvider>
                  <DatabridgeAdminContextProvider>
                    <MachineCertificatesProvider>
                      <CompaniesContextProvider>
                        <CompanyAccessContextProvider>
                          <UserCompaniesContextProvider>
                            <PermissionGroupsContextProvider>
                              <UserUsersContextProvider>
                                <UserMachinesContextProvider>
                                  <UserProfileContextProvider>
                                    <UserMachineAccessContextProvider>
                                      <AdminUsersContextProvider>
                                        <NotificationContextProvider>
                                          <AdminAnalyticsContextProvider>
                                            <ApiLogContextProvider>
                                              <MachineRequestsContextProvider>
                                                <MachineRequestFiltersContextProvider>
                                                  <MachineDashboardContextProvider>
                                                    {children}
                                                  </MachineDashboardContextProvider>
                                                </MachineRequestFiltersContextProvider>
                                              </MachineRequestsContextProvider>
                                            </ApiLogContextProvider>
                                          </AdminAnalyticsContextProvider>
                                        </NotificationContextProvider>
                                      </AdminUsersContextProvider>
                                    </UserMachineAccessContextProvider>
                                  </UserProfileContextProvider>
                                </UserMachinesContextProvider>
                              </UserUsersContextProvider>
                            </PermissionGroupsContextProvider>
                          </UserCompaniesContextProvider>
                        </CompanyAccessContextProvider>
                      </CompaniesContextProvider>
                    </MachineCertificatesProvider>
                  </DatabridgeAdminContextProvider>
                </ExternalApiPartnersContextProvider>
              </SoftwareVersionContextProvider>
            </MachineDeploymentContextProvider>
          </MachineCertificatesProvider>
        </MachinesContextProvider>
      </ManufacturersContextProvider>
    </AuthContextProvider>
  );
};

export default Contexts;
