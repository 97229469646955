import { IDataTableColumn } from '../../dataTable/DataTable/model';

const liquidColumns: IDataTableColumn<any>[] = [
  { name: 'Autotreat MFR Liquid Cloud ID', selector: (row) => row.autotreat_mfr_liquid_cloud_id, sortable: true },
  { name: 'Global Liquid ID', selector: (row) => row.global_liquid_id, sortable: true },
  { name: 'Global MFR ID', selector: (row) => row.global_mfr_id, sortable: true },
  { name: 'Liquid Name', selector: (row) => row.liquid_name, sortable: true },
  { name: 'MFR Product ID', selector: (row) => row.mfr_product_id, sortable: true },
  { name: 'Liquid Name Description', selector: (row) => row.liquid_name_desc, sortable: true },
  { name: 'EPA Number', selector: (row) => row.epa_number, sortable: true },
  { name: 'Liquid Density', selector: (row) => row.liquid_density, sortable: true },
  { name: 'Liquid Type ID', selector: (row) => row.liquid_type_id, sortable: true },
  { name: 'Liquid Type', selector: (row) => row.liquid_type, sortable: true },
  { name: 'Min Rate', selector: (row) => row.min_rate, sortable: true },
  { name: 'Max Rate', selector: (row) => row.max_rate, sortable: true },
  { name: 'Target Rate', selector: (row) => row.target_rate, sortable: true },
  { name: 'Liquid Density Tolerance', selector: (row) => row.liquid_density_tol, sortable: true },
  {
    name: 'Adjustable',
    selector: (row) => row.adjustable,
    sortable: true,
    cell: (row) => (row.adjustable ? 'True' : 'False'),
  },
  { name: 'Adjust By', selector: (row) => row.adjust_by, sortable: true },
  { name: 'Locked', selector: (row) => row.locked, sortable: true, cell: (row) => (row.locked ? 'True' : 'False') },
  { name: 'Active', selector: (row) => row.active, sortable: true, cell: (row) => (row.active ? 'True' : 'False') },
  { name: 'Date and Time Created', selector: (row) => row.date_and_time_created, sortable: true },
];

export default liquidColumns;
