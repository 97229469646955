import React from 'react';
import { AutoTreatTransactionsContextProvider } from './autotreatModules/transactions/contexts/AutoTreatTransactionsContext';
import { AutoTreatFiltersContextProvider } from './autotreatModules/transactions/contexts/AutoTreatFiltersContext';
import { SeedVarietiesContextProvider } from './autotreatModules/transactions/contexts/SeedVarietiesContext';
import { AutoTreatRealTimeActivityContextProvider } from './autotreatModules/realTimeDashboard/contexts/AutoTreatRealTimeActivityContext';
import { SeedsAutoTreatContextProvider } from './autotreatModules/seeds/contexts/SeedsAutoTreatContext';
import { SeedsAutoTreatValidationsContextProvider } from './autotreatModules/seeds/contexts/SeedsAutoTreatValidationsContext';
import { CustomersAutoTreatContextProvider } from './autotreatModules/customers/contexts/CustomersAutoTreatContext';
import { CustomersAutoTreatImportContextProvider } from './autotreatModules/customers/contexts/CustomersAutoTreatImportContext';
import { TreatmentProductsContextProvider } from './autotreatModules/transactions/contexts/TreatmentProductsContext';
import { TreatmentProductsAutoTreatContextProvider } from './autotreatModules/treatmentProducts/contexts/TreatmentProductsAutoTreatContext';
import { TreatmentProductsAutoTreatValidationsContextProvider } from './autotreatModules/treatmentProducts/contexts/TreatmentProductsAutoTreatValidationsContext';
import { RecipesAutoTreatContextProvider } from './autotreatModules/recipes/contexts/RecipesAutoTreatContext';
import { RecipeIntegrationsAutoTreatContextProvider } from './autotreatModules/recipes/contexts/RecipeIntegrationsAutoTreatContext';
import { OrdersAutoTreatContextProvider } from './autotreatModules/orders/contexts/OrdersAutoTreatContext';
import { LiquidSourcesAutoTreatContextProvider } from './autotreatModules/liquidSources/contexts/LiquidSourcesAutoTreatContext';
import { SeedSourcesAutoTreatContextProvider } from './autotreatModules/seedSources/contexts/SeedSourcesAutoTreatContext';
import { CustomersAutoTreatValidationsContextProvider } from './autotreatModules/customers/contexts/CustomersAutoTreatValidationsContext';
import { CustomerIntegrationsAutoTreatContextProvider } from './autotreatModules/customers/contexts/CustomerIntegrationsAutoTreatContext';
import { SeedIntegrationsAutoTreatContextProvider } from './autotreatModules/seeds/contexts/SeedsIntegrationsAutoTreatContext';
import { TreatmentProductIntegrationsAutoTreatContextProvider } from './autotreatModules/treatmentProducts/contexts/TreatmentProductIntegrationsAutoTreatContext';
import { TransactionApiLogsAutoTreatContextProvider } from './autotreatModules/transactionsApiLog/contexts/TransactionApiLogsAutoTreatContext';
import { SeedsAutoTreatImportContextProvider } from './autotreatModules/seeds/contexts/SeedsAutoTreatImportContext';
import { ManufacturersNewProvider } from './modules/manufacturerAdminManagement/contexts/ManufacturersNewContext';
import { ManufacturersEditContextProvider } from './modules/manufacturerAdminManagement/contexts/ManufacturersEditContext';
import { MfrRecipesProvider } from './modules/manufacturerAdminManagement/contexts/MfrRecipesContext';
import { MfrRecipesNewProvider } from './modules/manufacturerAdminManagement/contexts/MfrRecipesNewContext';
import { MfrLiquidsProvider } from './modules/manufacturerAdminManagement/contexts/MfrLiquidsContext';
import { MfrSeedsProvider } from './modules/manufacturerAdminManagement/contexts/MfrSeedsContext';
import { MfrGTINPrefixesProvider } from './modules/manufacturerAdminManagement/contexts/MfrGTINPrefixesContext';
import { MachineDevicesAutoTreatContextProvider } from './modules/machinesAdminManagement/contexts/MachineDevicesContext';

const AutoTreatContexts: React.FC = ({ children }) => {
  return (
    <MfrGTINPrefixesProvider>
      <MfrSeedsProvider>
        <MfrLiquidsProvider>
          <ManufacturersEditContextProvider>
            <ManufacturersNewProvider>
              <MfrRecipesProvider>
                <MfrRecipesNewProvider>
                  <AutoTreatTransactionsContextProvider>
                    <AutoTreatFiltersContextProvider>
                      <TransactionApiLogsAutoTreatContextProvider>
                        <SeedVarietiesContextProvider>
                          <AutoTreatRealTimeActivityContextProvider>
                            <SeedsAutoTreatContextProvider>
                              <SeedsAutoTreatImportContextProvider>
                                <SeedsAutoTreatValidationsContextProvider>
                                  <CustomersAutoTreatContextProvider>
                                    <CustomersAutoTreatImportContextProvider>
                                      <TreatmentProductsContextProvider>
                                        <TreatmentProductsAutoTreatContextProvider>
                                          <TreatmentProductsAutoTreatValidationsContextProvider>
                                            <RecipeIntegrationsAutoTreatContextProvider>
                                              <RecipesAutoTreatContextProvider>
                                                <OrdersAutoTreatContextProvider>
                                                  <LiquidSourcesAutoTreatContextProvider>
                                                    <SeedSourcesAutoTreatContextProvider>
                                                      <CustomersAutoTreatValidationsContextProvider>
                                                        <CustomerIntegrationsAutoTreatContextProvider>
                                                          <SeedIntegrationsAutoTreatContextProvider>
                                                            <TreatmentProductIntegrationsAutoTreatContextProvider>
                                                              <MachineDevicesAutoTreatContextProvider>
                                                                {children}
                                                              </MachineDevicesAutoTreatContextProvider>
                                                            </TreatmentProductIntegrationsAutoTreatContextProvider>
                                                          </SeedIntegrationsAutoTreatContextProvider>
                                                        </CustomerIntegrationsAutoTreatContextProvider>
                                                      </CustomersAutoTreatValidationsContextProvider>
                                                    </SeedSourcesAutoTreatContextProvider>
                                                  </LiquidSourcesAutoTreatContextProvider>
                                                </OrdersAutoTreatContextProvider>
                                              </RecipesAutoTreatContextProvider>
                                            </RecipeIntegrationsAutoTreatContextProvider>
                                          </TreatmentProductsAutoTreatValidationsContextProvider>
                                        </TreatmentProductsAutoTreatContextProvider>
                                      </TreatmentProductsContextProvider>
                                    </CustomersAutoTreatImportContextProvider>
                                  </CustomersAutoTreatContextProvider>
                                </SeedsAutoTreatValidationsContextProvider>
                              </SeedsAutoTreatImportContextProvider>
                            </SeedsAutoTreatContextProvider>
                          </AutoTreatRealTimeActivityContextProvider>
                        </SeedVarietiesContextProvider>
                      </TransactionApiLogsAutoTreatContextProvider>
                    </AutoTreatFiltersContextProvider>
                  </AutoTreatTransactionsContextProvider>
                </MfrRecipesNewProvider>
              </MfrRecipesProvider>
            </ManufacturersNewProvider>
          </ManufacturersEditContextProvider>
        </MfrLiquidsProvider>
      </MfrSeedsProvider>
    </MfrGTINPrefixesProvider>
  );
};

export default AutoTreatContexts;
