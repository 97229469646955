import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EditManufacturerForm from '../components/EditManufacturerForm';

const EditManufacturerPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const handleSuccess = () => {
    history.push('/admin/manufacturer_management');
  };

  return (
    <div>
      <EditManufacturerForm onSuccess={handleSuccess} />
    </div>
  );
};

export default EditManufacturerPage;
