import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../utils/axios.utils';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface SoftwareVersionContextInterface {
  fetchSoftwareVersions?: () => Promise<void>;
  createSoftwareVersion?: (formValues: any, file: any) => Promise<void>;
  deleteSoftwareVersion?: (softwareVersionId: any, onSuccess?: () => void) => Promise<void>;
  softwareVersionDeleting?: boolean;

  softwareVersion?: any[];

  softwareVersions?: any;
  softwareVersionsLoading?: boolean;

  softwareVersionsSubmitting?: boolean;
  setSoftwareVersionsSubmitting?: Dispatch<SetStateAction<boolean>>;
}

const SoftwareVersionContext = React.createContext<SoftwareVersionContextInterface>({});

const SoftwareVersionContextConsumer = SoftwareVersionContext.Consumer;
const SoftwareVersionContextProvider: React.FC = ({ children }) => {
  const { showSuccessSnackBar, showErrorSnackBar } = React.useContext(SnackBarContext);

  const [softwareVersion, setSoftwareVersion] = React.useState([]);
  const [softwareVersions, setSoftwareVersions] = React.useState([]);
  const [softwareVersionsLoading, setSoftwareVersionsLoading] = React.useState(true);
  const [softwareVersionDeleting, setSoftwareVersionDeleting] = React.useState(true);

  const [softwareVersionsSubmitting, setSoftwareVersionsSubmitting] = React.useState(false);

  const fetchSoftwareVersions = async () => {
    setSoftwareVersionsLoading(true);

    axios
      .get<string, any>(`api/admin/software_versions/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        console.log(response);
        setSoftwareVersions(response.data.results);
        setSoftwareVersionsLoading(false);
      });
  };

  const createSoftwareVersion = async (formValues: any) => {
    setSoftwareVersionsSubmitting(true);
    showSuccessSnackBar('Creating software version');

    console.log('formValues in context:');
    console.log(formValues);

    try {
      const formData = new FormData();
      formData.append('machine_software_type_id', formValues.machine_software_type_id);
      formData.append('machine_type_id', formValues.machine_type_id);
      formData.append('machine_software_version_name', formValues.machine_software_version_name);
      formData.append('installer_s3_path', formValues.installer_s3_path);
      formData.append('release_notes', formValues.release_notes);
      formData.append('file', formValues.file);
      formData.append('status', formValues.status);

      const response = await axios.post(`api/admin/software_versions/`, formData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response);
      setSoftwareVersionsSubmitting(false);
      showSuccessSnackBar('Software version created');
    } catch (error) {
      console.error('Error creating software version:', error);
      showErrorSnackBar('Error creating software version');
      setSoftwareVersionsSubmitting(false);
    }
  };

  const deleteSoftwareVersion = async (softwareVersionId: any, onSuccess?: () => void) => {
    setSoftwareVersionDeleting(true);
    showSuccessSnackBar('Deleting software version');

    axios
      .delete<string, any>(`api/admin/software_versions/${softwareVersionId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setSoftwareVersionDeleting(false);
        showSuccessSnackBar('Software version deleted');

        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        console.error('Error creating machine deployment:', error);
        showErrorSnackBar('Error deleting software version');
        setSoftwareVersionDeleting(false);
      });
  };

  return (
    <SoftwareVersionContext.Provider
      value={{
        fetchSoftwareVersions,
        createSoftwareVersion,
        deleteSoftwareVersion,
        softwareVersionDeleting,
        softwareVersion,

        softwareVersions,
        softwareVersionsLoading,

        softwareVersionsSubmitting,
      }}
    >
      {children}
    </SoftwareVersionContext.Provider>
  );
};

export { SoftwareVersionContextProvider, SoftwareVersionContextConsumer, SoftwareVersionContext };
