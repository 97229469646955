import React, { useEffect, useState, useContext } from 'react';
import { Grid, TextField, Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import { ExternalApiPartnersContext, ExternalApiPartner } from '../contexts/ExternalApiPartnersContext';

interface DatabridgeIntegrationFormProps {
  integrationDetails?: any; // Updated to match the API response structure
}

const DatabridgeIntegrationForm: React.FC<DatabridgeIntegrationFormProps> = ({ integrationDetails }) => {
  console.log('integrationDetails');
  console.log(integrationDetails);

  const { updatePartner, createPartner, partnerSubmitting } = useContext(ExternalApiPartnersContext);

  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    enabled: false,
    adapter_name: '',
    partner_name: '',
    aws_secret_id: '',
    refresh_token: '',
    authorization_code: '',
    databridge_integration_id: '',
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [generatedCredentials, setGeneratedCredentials] = useState<any>({});

  useEffect(() => {
    if (integrationDetails) {
      setFormValues({
        name: integrationDetails.name || '',
        description: integrationDetails.partner?.description || '',
        enabled: integrationDetails.enabled || false,
        adapter_name: integrationDetails.adapter?.name || '',
        partner_name: integrationDetails.partner?.name || '',
        aws_secret_id: integrationDetails.aws_secret_id || '',
        refresh_token: integrationDetails.refresh_token || '',
        authorization_code: integrationDetails.authorization_code || '',
        databridge_integration_id: integrationDetails.databridge_integration_id?.toString() || '',
      });
    }
  }, [integrationDetails]);

  const handleSave = async () => {
    if (integrationDetails?.partner?.external_api_partner_id) {
      await updatePartner?.(integrationDetails.partner.external_api_partner_id.toString(), formValues, () => null);
    } else {
      const response = await createPartner?.(formValues, setGeneratedCredentials);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setGeneratedCredentials(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Name"
            value={formValues.name}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Description"
            value={formValues.description}
            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Adapter Name"
            value={formValues.adapter_name}
            onChange={(e) => setFormValues({ ...formValues, adapter_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Partner Name"
            value={formValues.partner_name}
            onChange={(e) => setFormValues({ ...formValues, partner_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="AWS Secret ID"
            value={formValues.aws_secret_id}
            onChange={(e) => setFormValues({ ...formValues, aws_secret_id: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Refresh Token"
            value={formValues.refresh_token}
            onChange={(e) => setFormValues({ ...formValues, refresh_token: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Authorization Code"
            value={formValues.authorization_code}
            onChange={(e) => setFormValues({ ...formValues, authorization_code: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled
            label="Integration ID"
            value={formValues.databridge_integration_id}
            onChange={(e) => setFormValues({ ...formValues, databridge_integration_id: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.enabled}
                onChange={(e) => setFormValues({ ...formValues, enabled: e.target.checked })}
              />
            }
            label="Enabled"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={partnerSubmitting}>
            {partnerSubmitting
              ? 'Saving...'
              : integrationDetails?.partner?.external_api_partner_id
              ? 'Update Integration'
              : 'Create Integration'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DatabridgeIntegrationForm;
