/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, CardActions, Button } from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { LoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';
import EdgelinkLiveFilterBar from '../components/EdgelinkLiveFilterBar';

const useStyles = makeStyles((theme: any) => ({
  mapContainer: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
    },
  },
}));

// Populate the API URL prefix via an environment variable, defaulting to http://localhost:8000 if not provided.
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_API_KEY;

const EdgelinkDevicesDashboard: React.FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState({
    serial_number: '',
    product_name: '',
    fill_status: '',
    dealer: '',
  });

  const [selectedMarker, setSelectedMarker] = React.useState<any | null>(null);
  const [map, setMap] = React.useState(null);

  // Changed state initialization: start with an empty array.
  const [levelSensors, setLevelSensors] = React.useState<any[]>([]);
  const [loadingSensors, setLoadingSensors] = React.useState<boolean>(false);

  // Fetch sensor data from the API endpoint.
  React.useEffect(() => {
    const fetchLevelSensors = async () => {
      setLoadingSensors(true);
      axios
        .get<any, any>(`${API_URL}/api/user/edgelink/devices/dashboard`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          if (response.data && response.data.success) {
            setLevelSensors(response.data.results);
          } else {
            setLevelSensors([]);
          }
          setLoadingSensors(false);
        })
        .catch((error) => {
          console.error('Error fetching sensors:', error);
          setLevelSensors([]);
          setLoadingSensors(false);
        });
    };

    fetchLevelSensors();
  }, []);

  const handleMarkerClick = (marker: any) => {
    setSelectedMarker(marker);
    if (map) {
      map.panTo({ lat: marker.gps_lat, lng: marker.gps_long }); // Smoothly pan the map to the clicked marker
    }
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Device Dashboard
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <EdgelinkLiveFilterBar tableFilters={tableFilters} setTableFilters={setTableFilters} />
          <br />
        </Grid>
      </Grid>
      <br />
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} lg={12} className={classes.mapContainer}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '40vh' }}
              center={{ lat: 39.833333, lng: -94.5855522 }}
              zoom={5}
              onLoad={(mapInstance) => setMap(mapInstance)} // Capture map instance
            >
              {levelSensors?.map((marker) => (
                <Marker
                  key={marker.edgelink_device_id}
                  position={{ lat: marker.gps_lat, lng: marker.gps_long }}
                  onClick={() => handleMarkerClick(marker)}
                />
              ))}
              {selectedMarker && (
                <InfoWindow
                  position={{ lat: selectedMarker.gps_lat, lng: selectedMarker.gps_long }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <Card variant="outlined" style={{ maxWidth: 350 }}>
                    <CardContent>
                      <Typography variant="h6" component="div" gutterBottom>
                        Device Details
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Serial: {selectedMarker.device_serial_number}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Microcontroller:</strong> {selectedMarker.microcontroller_serial}
                      </Typography>
                      {/* <Typography variant="body2">
                        <strong>State:</strong> {selectedMarker.connection_state}
                      </Typography> */}
                      <Typography variant="body2">
                        <strong>Last Seen:</strong> {selectedMarker.last_seen_at}
                      </Typography>
                      <Typography variant="body2">
                        <strong>SIM IMSI:</strong> {selectedMarker.sim_imsi}
                      </Typography>
                      <Typography variant="body2">
                        <strong>GPS:</strong> {selectedMarker.gps_lat}, {selectedMarker.gps_long}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Temperature:</strong> {selectedMarker.temperature_in_f}°F
                      </Typography>
                      <Typography variant="body2">
                        <strong>Cellular Strength:</strong> {selectedMarker.cellular_strength}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Battery Volts:</strong> {selectedMarker.battery_volts}V
                      </Typography>
                      <Typography variant="body2">
                        <strong>Solar Volts:</strong> {selectedMarker.solar_volts}V
                      </Typography>
                      <Typography variant="body2">
                        <strong>Product:</strong> {selectedMarker.product_name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Crop:</strong> {selectedMarker.product_crop}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Units:</strong> {selectedMarker.product_quantity}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Fill Status:</strong> {selectedMarker.fill_status}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Dealer:</strong> {selectedMarker.first_assigned_company_name}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {selectedMarker?.edgelink_device_id ? (
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => history.push(`/edgelink/${selectedMarker.edgelink_device_id}/dashboard`)}
                        >
                          View More
                        </Button>
                      ) : (
                        <Typography variant="body2" color="error">
                          Unable to load device details.
                        </Typography>
                      )}
                    </CardActions>
                  </Card>
                </InfoWindow>
              )}
            </GoogleMap>
          </Grid>
        </Grid>
      </LoadScript>
      <DataTable
        noHeader
        columns={columns}
        data={levelSensors}
        defaultSortField="DateAndTime"
        defaultSortAsc={false}
        striped
        highlightOnHover
        pointerOnHover
        pagination
        paginationPerPage={50}
        paginationRowsPerPageOptions={[10, 25, 50]}
      />
    </>
  );
};

export default EdgelinkDevicesDashboard;
