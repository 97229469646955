/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { Machine } from '../../machinesAdminManagement/model';
import DataTable from '../../dataTable/DataTable/DataTable';
import { MachineDeploymentContext } from '../contexts/DeploymentsContext';
import { useHistory } from 'react-router-dom';
import responseColumns from '../constants/responseColumns';

const DeploymentsTemporaryResponse: React.FunctionComponent = () => {
  const history = useHistory();

  const { jsonResponse, setShowResponsePage } = React.useContext(MachineDeploymentContext);

  const handleBack = () => {
    history.push(`/admin/machine_deployments/create`);
    setShowResponsePage(false);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Deployment Create
          </Typography>
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Machine Deployments Responses
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={0}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                Post Endpont Responses
              </Typography>
            </Grid>
          </Grid>
          <br />
          <DataTable
            noHeader
            columns={responseColumns}
            data={jsonResponse}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={false}
          />
        </CardContent>
        <br />
      </Card>
      <br />
    </>
  );
};

export default DeploymentsTemporaryResponse;
