/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { SoftwareVersionFormValues } from '../model';
import { SoftwareVersionContext } from '../contexts/SoftwareVersionsContext';
// import FileSelector from './fileUploader/fileSelector';
// import { SoftwareVersionsContext } from '../contexts/SoftwareVersionsContext';
// import { SoftwareVersionsValidationsContext } from '../contexts/SoftwareVersionsValidationsContext';

interface SoftwareVersionFormProps {
  softwareVersion?: any;
  onBack?: () => void;
}

const SoftwareVersionForm: React.FC<SoftwareVersionFormProps> = (props) => {
  const { softwareVersion, onBack } = props;

  const { createSoftwareVersion, softwareVersionsSubmitting } = React.useContext(SoftwareVersionContext);
  // const { validateSoftwareVersionsFields, formErrors } = React.useContext(SoftwareVersionsValidationsContext);
  // const [formValues, setFormValues] = React.useState<SoftwareVersionFormValues>({});
  const [formValues, setFormValues] = React.useState<any>({});
  const [file, setFile] = React.useState<any>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    // updateSoftwareVersion(softwareVersion.softwareVersion_id, formValues);
  };

  const handleCreate = () => {
    createSoftwareVersion(formValues, file);
  };

  React.useEffect(() => {
    // const abSoftwareVersionsFieldsValid = validateSoftwareVersionsFields(formValues);
    // setActionButtonDisabled(!abSoftwareVersionsFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      machine_software_version_id: softwareVersion?.machine_software_version_id,
      machine_software_type_id: softwareVersion?.machine_software_type_id,
      machine_type_id: softwareVersion?.machine_type_id,
      machine_software_version_name: softwareVersion?.machine_software_version_name,
      installer_s3_path: softwareVersion?.installer_s3_path,
      release_notes: softwareVersion?.release_notes || '',
      created_by_user: softwareVersion?.created_by_user,
      created_date_time: softwareVersion?.created_date_time,
      last_modified_by_user: softwareVersion?.last_modified_by_user,
      last_modified_date_time: softwareVersion?.last_modified_date_time,
      file: {},
      status: softwareVersion?.status || 'True',
    });
  }, [softwareVersion]);

  React.useEffect(() => {
    if (
      formValues?.machine_software_type_id &&
      formValues?.machine_type_id &&
      formValues?.machine_software_version_name
      // formValues?.installer_s3_path
    ) {
      setActionButtonDisabled(false);
    } else {
      setActionButtonDisabled(true);
    }
  }, [softwareVersion, formValues]);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormValues({ ...formValues, file: file, installer_s3_path: file.name });
      // setFile(file)
    }
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={formValues?.machine_type_id}
                choices={[
                  { label: 'AutoTreat', value: '1' },
                  { label: 'AutoBulk', value: '2' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, machine_type_id: value })}
                label="Machine Type"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                value={formValues?.machine_software_type_id}
                choices={[
                  { label: 'Database', value: '1' },
                  { label: 'Indusoft', value: '2' },
                  { label: 'LocalApp', value: '3' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, machine_software_type_id: value })}
                label="Software Type"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                value={formValues?.machine_software_version_name}
                onChange={(value) => setFormValues({ ...formValues, machine_software_version_name: value })}
                label="Software Version Name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                value={formValues?.release_notes}
                onChange={(value) => setFormValues({ ...formValues, release_notes: value })}
                multiline
                rows={3}
                label="Release Notes"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                {/* {`Upload file: ${formValues?.file ? formValues?.file?.name : ''}`} */}
                <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormInput
                value={formValues?.file ? formValues.file?.name : ''}
                // onChange={(value) => setFormValues({ ...formValues, installer_s3_path: value })}
                multiline
                rows={1}
                disabled
                label="Selected File Name"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                value={formValues?.status}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, status: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          <>
            {softwareVersion?.softwareVersion_id ? (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || softwareVersionsSubmitting}
                onClick={handleUpdate}
              >
                {softwareVersionsSubmitting ? 'Updating...' : 'Update'}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || softwareVersionsSubmitting}
                onClick={handleCreate}
              >
                {softwareVersionsSubmitting ? 'Creating...' : 'Create'}
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={onBack} style={{ marginLeft: 8 }}>
              Cancel
            </Button>
          </>
        </form>
      </Grid>
    </Grid>
  );
};

export default SoftwareVersionForm;
