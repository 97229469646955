import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

const MachinesConfiguration = ({ handleMachineKeyValueChange, saveMachineConfig, selectedIntegration }) => {
  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardHeader title="Machines Configuration" />
      <Divider />
      <CardContent>
        {selectedIntegration && selectedIntegration.machines && selectedIntegration.machines.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Machine ID</TableCell>
                <TableCell>Machine Name</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Key Values</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedIntegration.machines.map((machine) => (
                <TableRow key={machine.machine_id}>
                  <TableCell>{machine.machine_id}</TableCell>
                  <TableCell>{machine.machine_name}</TableCell>
                  <TableCell>{machine.machine_serial_number}</TableCell>
                  <TableCell>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>View/Edit Key Values</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {machine.key_values && machine.key_values.length > 0 ? (
                          machine.key_values.map((kv) => {
                            let inputField;
                            if (kv.value === 'true' || kv.value === 'false') {
                              inputField = (
                                <Checkbox
                                  checked={kv.value === 'true'}
                                  onChange={(e) =>
                                    handleMachineKeyValueChange(
                                      machine.machine_id,
                                      kv.databridge_integration_machine_key_value_id,
                                      e.target.checked.toString(),
                                    )
                                  }
                                />
                              );
                            } else if (kv.value && kv.value.startsWith('[') && kv.value.endsWith(']')) {
                              inputField = (
                                <TextField
                                  multiline
                                  fullWidth
                                  value={kv.value}
                                  onChange={(e) =>
                                    handleMachineKeyValueChange(
                                      machine.machine_id,
                                      kv.databridge_integration_machine_key_value_id,
                                      e.target.value,
                                    )
                                  }
                                />
                              );
                            } else {
                              inputField = (
                                <TextField
                                  fullWidth
                                  value={kv.value || ''}
                                  onChange={(e) =>
                                    handleMachineKeyValueChange(
                                      machine.machine_id,
                                      kv.databridge_integration_machine_key_value_id,
                                      e.target.value,
                                    )
                                  }
                                />
                              );
                            }
                            return (
                              <Box key={kv.databridge_integration_machine_key_value_id} mb={2}>
                                <Typography variant="subtitle2" gutterBottom>
                                  {kv.name || kv.key}
                                </Typography>
                                {inputField}
                              </Box>
                            );
                          })
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            No key values found for this machine.
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          color="success"
                          disabled
                          startIcon={<SaveIcon />}
                          onClick={() => saveMachineConfig(machine.machine_id)}
                        >
                          Save Machine Config
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="No specific actions implemented">
                      <IconButton disabled>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No machines associated with this integration.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MachinesConfiguration;
