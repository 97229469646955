/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { LoadScript, GoogleMap, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { Bar, Line } from 'react-chartjs-2';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_API_KEY;

interface ISensorUom {
  uom_id: number;
  uom: string;
  uom_abbreviation: string;
  value: number | string;
}

interface ISensorEventData {
  sensor?: string;
  sensor_type_id?: number;
  sensor_type_name?: string;
  uoms: ISensorUom[];
}

interface IEvent {
  event_id: number;
  time_stamp: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  gps_lat: number;
  gps_long: number;
  sensor_event_data: ISensorEventData[];
}

interface IDeviceData {
  edgelink_device_id: number;
  device_serial_number: string;
  microcontroller_serial: string;
  connection_state: string;
  last_seen_at: string;
  sim_imsi: string | null;
  product_name: string;
  product_crop: string;
  product_quantity: number;
  first_assigned_company_name: string;
  address: string;
  events: IEvent[];
}

const DeviceDashboard: React.FC = () => {
  const { edgelinkId } = useParams<{ edgelinkId: string }>();
  console.log('Received edgelinkId:', edgelinkId);

  // State for device data
  const [deviceData, setDeviceData] = useState<IDeviceData | null>(null);
  // Loading / Error states
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // Google Maps
  const [map, setMap] = useState<any>(null);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  // --- Fetch single device data
  useEffect(() => {
    if (!edgelinkId) {
      setError('Device ID is missing or invalid');
      return;
    }

    const fetchDeviceData = async () => {
      setLoading(true);
      try {
        const resp = await axios.get<any, any>(`${API_URL}/api/user/edgelink/devices/${edgelinkId}/dashboard`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        });

        if (resp.data && resp.data.success) {
          setDeviceData(resp.data.results);
          setError('');
        } else {
          setDeviceData(null);
          setError('Failed to fetch device data');
        }
      } catch (e: any) {
        console.error(e);
        setError('Error fetching device data');
      }
      setLoading(false);
    };

    fetchDeviceData();
  }, [edgelinkId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={2}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!deviceData) {
    return null; // or a placeholder
  }

  // Tank inventory data for Vertical BarGraph
  const latestEvent = deviceData.events[0];
  let amountRemaining = 0;
  let totalCapacity = 0;

  if (latestEvent) {
    const amt = latestEvent.sensor_event_data.find((sed) => sed.sensor === 'amount_remaining');
    const cap = latestEvent.sensor_event_data.find((sed) => sed.sensor === 'total_capacity');
    if (amt && amt.uoms.length > 0) {
      amountRemaining = typeof amt.uoms[0].value === 'number' ? amt.uoms[0].value : 0;
    }
    if (cap && cap.uoms.length > 0) {
      totalCapacity = typeof cap.uoms[0].value === 'number' ? cap.uoms[0].value : 0;
    }
  }

  const inventoryData = {
    labels: ['Tank Capacity'],
    datasets: [
      {
        label: 'Remaining',
        data: [amountRemaining],
        backgroundColor: 'rgba(25, 118, 210, 0.7)', // Blue overlay
      },
      {
        label: 'Used',
        data: [totalCapacity - amountRemaining],
        backgroundColor: 'rgba(207, 216, 220, 0.5)', // Gray capacity
      },
    ],
  };

  const inventoryOptions = {
    responsive: true,
    maintainAspectRatio: false, // Uses full card height and width
    plugins: {
      legend: {
        position: 'top', // Keeps the legend above the chart
      },
    },
    indexAxis: 'x', // Switch to vertical bar graph
    scales: {
      y: {
        beginAtZero: true,
        stacked: true, // Ensures overlay effect
        ticks: {
          stepSize: totalCapacity / 4, // Dynamic step size based on capacity
        },
      },
      x: {
        beginAtZero: true,
        max: totalCapacity, // Maximum set to total capacity
        stacked: true, // Ensures proper layering
      },
    },
  };

  // Temperature data for Line Chart
  const temperatureData = {
    labels: deviceData.events.map((evt) =>
      new Date(evt.time_stamp).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }),
    ),
    datasets: [
      {
        label: 'Temperature (°F)',
        data: deviceData.events.map((evt) => {
          const temp = evt.sensor_event_data.find((sed) => sed.sensor_type_name === 'temperature');
          return temp?.uoms.find((u) => u.uom_abbreviation === '°F')?.value || null;
        }),
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.1,
      },
    ],
  };

  // Cellular strength data for Line Chart
  const cellularData = {
    labels: deviceData.events.map((evt) =>
      new Date(evt.time_stamp).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }),
    ),
    datasets: [
      {
        label: 'Cellular Signal (dBm)',
        data: deviceData.events.map((evt) => {
          const signal = evt.sensor_event_data.find((sed) => sed.sensor === 'cellular_strength');
          return signal?.uoms.find((u) => u.uom_abbreviation === 'dBm')?.value || null;
        }),
        fill: false,
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.1,
      },
    ],
  };
  // For the map, let's gather the path from the events
  const path = deviceData.events.map((evt) => ({
    lat: evt.gps_lat,
    lng: evt.gps_long,
  }));

  // Decide on a center for the map:
  const firstLat = deviceData.events[0]?.gps_lat || 39.833333;
  const firstLng = deviceData.events[0]?.gps_long || -94.5855522;

  const handleMarkerClick = (evt: IEvent) => {
    setSelectedEvent(evt);
    if (map) {
      map.panTo({ lat: evt.gps_lat, lng: evt.gps_long });
    }
  };

  return (
    <Box px={2} py={2}>
      {/* ---- DEVICE HEADER ---- */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Controller Details
              </Typography>
              <Typography variant="body1">
                <strong>Microcontroller:</strong> {deviceData.microcontroller_serial}
              </Typography>
              <Typography variant="body1">
                <strong>Connection State:</strong> {deviceData.connection_state}
              </Typography>
              <Typography variant="body1">
                <strong>Location::</strong> {deviceData.address}
              </Typography>
              <Typography variant="body1">
                <strong>Last Seen:</strong> {deviceData.last_seen_at}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Product Details
              </Typography>
              <Typography variant="body1">
                <strong>Dealer:</strong> {deviceData.first_assigned_company_name}
              </Typography>
              <Typography variant="body1">
                <strong>Product:</strong> {deviceData.product_name} ({deviceData.product_crop})
              </Typography>
              <Typography variant="body1">
                <strong>Seed Units:</strong> {deviceData.product_quantity}
              </Typography>
              <Typography variant="body1">
                <strong>Current Amount:</strong> {amountRemaining} gal
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* ---- MAIN GRID LAYOUT ---- */}
      <Grid container spacing={2}>
        {/* Tank Inventory */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Tank Inventory
              </Typography>
              <Box height={300}>
                <Bar data={inventoryData as any} options={inventoryOptions as any} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Map Section */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Device Path on Map
              </Typography>
              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '300px' }}
                  center={{ lat: firstLat, lng: firstLng }}
                  zoom={5}
                  onLoad={(mapInstance) => setMap(mapInstance)}
                >
                  {deviceData.events.map((evt) => (
                    <Marker
                      key={evt.event_id}
                      position={{ lat: evt.gps_lat, lng: evt.gps_long }}
                      onClick={() => handleMarkerClick(evt)}
                    />
                  ))}
                  <Polyline
                    path={path}
                    options={{
                      strokeColor: '#1976d2',
                      strokeWeight: 4,
                    }}
                  />
                  {selectedEvent && (
                    <InfoWindow
                      position={{ lat: selectedEvent.gps_lat, lng: selectedEvent.gps_long }}
                      onCloseClick={() => setSelectedEvent(null)}
                    >
                      <Box sx={{ p: 1 }}>
                        <Typography variant="subtitle2">Event Time: {selectedEvent.time_stamp}</Typography>
                        <Typography variant="body2">
                          {selectedEvent.address}, {selectedEvent.city}, {selectedEvent.state}
                        </Typography>
                      </Box>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* ---- SENSOR DATA SECTIONS ---- */}
      <Box mt={2}>
        <Typography variant="h2" gutterBottom>
          Sensor Data
        </Typography>
        <Grid container spacing={2}>
          {/* Temperature Line Chart */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  Temperature (°F) Over Time
                </Typography>
                <Box height={220}>
                  <Line data={temperatureData} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Cellular Strength Line Chart */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  Cellular Signal (dBm) Over Time
                </Typography>
                <Box height={220}>
                  <Line data={cellularData} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* ---- EVENT HISTORY ---- */}
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Event History
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {deviceData.events.map((evt) => (
            <Box key={evt.event_id} mb={2}>
              <Typography variant="subtitle2">
                <strong>{evt.time_stamp}</strong> - {evt.address}, {evt.city}, {evt.state}
              </Typography>
              {evt.sensor_event_data.map((sed, idx) => (
                <Box key={idx} ml={2}>
                  <Typography variant="body2">
                    <strong>{sed.sensor_type_name || sed.sensor}:</strong>{' '}
                    {sed.uoms
                      .map((u) =>
                        typeof u.value === 'number' ? `${u.value.toFixed(2)} ${u.uom_abbreviation} ` : `${u.value} `,
                      )
                      .join(', ')}
                  </Typography>
                </Box>
              ))}
              <Divider sx={{ my: 1 }} />
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};

export default DeviceDashboard;
