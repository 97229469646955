import React, { createContext, useState } from 'react';
import axios from '../../utils/axios.utils';

interface ManufacturersEditContextInterface {
  formSubmitting: boolean;
  formErrors: Record<string, string>;
  fetchManufacturerById: (id: string) => Promise<any>;
}

export const ManufacturersEditContext = createContext<ManufacturersEditContextInterface | null>(null);

export const ManufacturersEditContextProvider: React.FC = ({ children }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const fetchManufacturerById = async (id: string) => {
    try {
      const response = await axios.get(`api/admin/autotreat_mfr/mfrs/${id}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching manufacturer with ID: ${id}`, error);
      throw error;
    }
  };

  return (
    <ManufacturersEditContext.Provider
      value={{
        formSubmitting,
        formErrors,
        fetchManufacturerById,
      }}
    >
      {children}
    </ManufacturersEditContext.Provider>
  );
};
