import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { DatabridgeIntegration } from '../model';

const integrationColumns: IDataTableColumn<DatabridgeIntegration>[] = [
  {
    name: 'ID',
    selector: (row) => row.databridge_integration_id.toString(),
    sortable: true,
    grow: 0.3,
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Adapter Name',
    selector: (row) => row.adapter_name,
    sortable: true,
  },
  {
    name: 'Partner Name',
    selector: (row) => row.partner_name,
    sortable: true,
  },
];

export default integrationColumns;
