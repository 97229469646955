import React, { createContext, useContext, useState } from 'react';
import axios from '../../utils/axios.utils';

interface MfrLiquidsContextInterface {
  liquids: any[];
  liquidsLoading: boolean;
  fetchLiquids: () => Promise<void>;
}

// Create the context
export const MfrLiquidsContext = createContext<MfrLiquidsContextInterface | null>(null);

// Provider Component
export const MfrLiquidsProvider: React.FC = ({ children }) => {
  const [liquids, setLiquids] = useState<any[]>([]);
  const [liquidsLoading, setLiquidsLoading] = useState(false);

  const fetchLiquids = async () => {
    setLiquidsLoading(true);
    try {
      const response = await axios.get(`/api/admin/autotreat_mfr/mfr_liquids/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      setLiquids(response.data);
    } catch (error) {
      console.error('Error fetching liquids:', error);
    } finally {
      setLiquidsLoading(false);
    }
  };

  return (
    <MfrLiquidsContext.Provider
      value={{
        liquids,
        liquidsLoading,
        fetchLiquids,
      }}
    >
      {children}
    </MfrLiquidsContext.Provider>
  );
};

// Hook for consuming the context
export const useMfrLiquidsContext = (): MfrLiquidsContextInterface => {
  const context = useContext(MfrLiquidsContext);
  if (!context) {
    throw new Error('useMfrLiquidsContext must be used within an MfrLiquidsProvider');
  }
  return context;
};
