import { IDataTableColumn } from '../../dataTable/DataTable/model';

const seedColumns: IDataTableColumn<any>[] = [
  { name: 'Autotreat MFR Seed Cloud ID', selector: (row) => row.autotreat_mfr_seed_cloud_id, sortable: true },
  { name: 'Global MFR ID', selector: (row) => row.global_mfr_id, sortable: true },
  { name: 'GTIN', selector: (row) => row.gtin, sortable: true },
  { name: 'Seed Variety Name MFR', selector: (row) => row.seed_variety_name_mfr, sortable: true },
  { name: 'Seed Variety Name Short', selector: (row) => row.seed_variety_name_short, sortable: true },
  { name: 'Seed Variety Desc', selector: (row) => row.seed_variety_desc, sortable: true },
  { name: 'Package', selector: (row) => row.package, sortable: true },
  { name: 'Crop ID', selector: (row) => row.crop?.crop_id, sortable: true },
  { name: 'Crop', selector: (row) => row.crop_name, sortable: true },
  { name: 'Locked', selector: (row) => row.locked, sortable: true, cell: (row) => (row.locked ? 'True' : 'False') },
  { name: 'Active', selector: (row) => row.active, sortable: true, cell: (row) => (row.active ? 'True' : 'False') },
  { name: 'Date and Time Created', selector: (row) => row.date_and_time_created, sortable: true },
];

export default seedColumns;
