import { IDataTableColumn } from '../../dataTable/DataTable/model';

const responseColumns: IDataTableColumn<any>[] = [
  {
    name: 'Machine Name',
    selector: 'machine_name',
    sortable: true,
  },
  {
    name: 'Machine Software Deployment Id',
    selector: 'machine_software_deployment_id',
    sortable: true,
  },
  {
    name: 'Machine Software Version Id',
    selector: 'machine_software_version_id',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Machine Software Version Name',
    selector: 'machine_software_version_name',
    sortable: true,
  },
  {
    name: 'Machine Software Version Type',
    selector: 'machine_software_version_type',
    sortable: true,
  },
  {
    name: 'Message',
    selector: 'message',
    sortable: true,
    grow: 3,
  },
];

export default responseColumns;
