/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography, Tabs, Tab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DatabridgeAdminContext } from '../contexts/DatabridgeAdminContext';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import { AuthContext } from '../../auth/contexts/AuthContext';
import DatabridgeIntegrationForm from '../components/DatabridgeIntegrationForm';
import DatabridgeMachinesConfiguration from '../components/DatabridgeMachinesConfiguration';

const DatabridgeIntegrationDetailPage: React.FC = () => {
  const { databridge_integration_id } = useParams<{ databridge_integration_id: string }>();

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { fetchDatabridgeIntegration, integrationDetails, integrationLoading } =
    React.useContext(DatabridgeAdminContext);

  React.useEffect(() => {
    fetchDatabridgeIntegration(databridge_integration_id);
  }, []);

  const saveMachineConfig = (machineId) => {
    // setOpenSnackbar(true);
  };

  const handleMachineKeyValueChange = (machineId, keyId, newValue) => {
    // if (!selectedIntegration) return;
    // const updatedIntegration = { ...selectedIntegration };
    // const machineIndex = updatedIntegration.machines.findIndex((m) => m.machine_id === machineId);
    // if (machineIndex === -1) return;
    // const machine = { ...updatedIntegration.machines[machineIndex] };
    // const kvIndex = machine.key_values.findIndex((kv) => kv.databridge_integration_machine_key_value_id === keyId);
    // if (kvIndex !== -1) {
    //   const updatedKV = { ...machine.key_values[kvIndex], value: newValue };
    //   machine.key_values[kvIndex] = updatedKV;
    // }
    // updatedIntegration.machines[machineIndex] = machine;
    // setSelectedIntegration(updatedIntegration);
  };

  const links = [
    {
      onClick: () => null,
      text: `API Management`,
    },
    {
      onClick: () => null,
      text: `Edit Integration`,
    },
  ];

  const handleBack = () => {};

  return (
    currentUserHasAdminPermission('ksi_admin') && (
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography
              style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
              onClick={handleBack}
            >
              ← Return to Integrations
            </Typography>
            <Breadcrumbs links={links} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <br />
            <Typography variant="h2" component="h2" style={{ marginBottom: 8 }}>
              Integration Details
            </Typography>
            <br />
            <DatabridgeIntegrationForm integrationDetails={integrationDetails} />
            <br />
            <DatabridgeMachinesConfiguration
              handleMachineKeyValueChange={handleMachineKeyValueChange}
              saveMachineConfig={saveMachineConfig}
              selectedIntegration={integrationDetails}
            />
            <br />
            <hr />
            <br />
            <Typography variant="h2" component="h2" style={{ marginBottom: 8 }}>
              Additional Actions
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default DatabridgeIntegrationDetailPage;
