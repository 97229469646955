import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Serial',
    selector: 'device_serial_number',
    sortable: true,
    grow: 0.8,
  },
  // {
  //   name: 'Microcontroller Serial',
  //   selector: 'microcontroller_serial',
  //   sortable: true,
  //   grow: 0.5,
  // },
  {
    name: 'Dealer',
    selector: 'first_assigned_company_name',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Product',
    selector: 'product_name',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Crop',
    selector: 'product_crop',
    sortable: true,
    grow: 0.75,
  },
  {
    name: 'Units',
    selector: 'product_quantity',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'City',
    selector: 'city',
    sortable: true,
    grow: 1,
  },
  {
    name: 'State',
    selector: 'state',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Fill Status',
    selector: 'fill_status',
    sortable: true,
    grow: 0.5,
  },
  // {
  //   name: 'Status',
  //   selector: 'level_fault',
  //   sortable: true,
  //   center: true,
  //   grow: 0.5,
  //   cell: (row: any) => <p>{row.level_fault === '1' ? 'Faulted' : 'OK'}</p>,
  // },
  // {
  //   name: 'Connection State',
  //   selector: 'connection_state',
  //   sortable: true,
  //   grow: 0.5,
  // },
  // {
  //   name: 'Latitude',
  //   selector: 'gps_lat',
  //   sortable: true,
  //   grow: 0.5,
  // },
  // {
  //   name: 'Longitude',
  //   selector: 'gps_long',
  //   sortable: true,
  //   grow: 0.5,
  // },
  {
    name: 'Temp (°F)',
    selector: 'temperature_in_f',
    sortable: true,
    grow: 0.8,
  },
  {
    name: 'Cell Strength',
    selector: 'cellular_strength',
    sortable: true,
    grow: 1,
  },
  // {
  //   name: 'Battery Volts',
  //   selector: 'battery_volts',
  //   sortable: true,
  //   grow: 0.5,
  // },
  // {
  //   name: 'Solar Volts',
  //   selector: 'solar_volts',
  //   sortable: true,
  //   grow: 0.5,
  // }
  {
    name: 'Last Seen At',
    selector: 'last_seen_at',
    sortable: true,
    grow: 1,
  },
];

export default columns;
