import { IDataTableColumn } from '../../dataTable/DataTable/model';

const mfrRecipeColumns: IDataTableColumn<any>[] = [
  {
    name: 'AutoTreat Recipe Cloud ID',
    selector: (row) => row.autotreat_mfr_recipe_cloud_id,
    sortable: true,
  },
  {
    name: 'Global Recipe ID',
    selector: (row) => row.global_recipe_id,
    sortable: true,
  },
  {
    name: 'Global MFR ID',
    selector: (row) => row.global_mfr_id,
    sortable: true,
  },
  {
    name: 'Liquid Recipe Name',
    selector: (row) => row.liquid_recipe_name,
    sortable: true,
  },
  {
    name: 'Liquid Recipe Description',
    selector: (row) => row.liquid_recipe_desc,
    sortable: true,
  },
  {
    name: 'Crop Name',
    selector: (row) => row.crop?.name || 'N/A',
    sortable: true,
  },
  {
    name: 'Recipe Volume Max',
    selector: (row) => row.recipe_volume_max,
    sortable: true,
  },
  {
    name: 'Recipe Volume Recommended',
    selector: (row) => row.recipe_volume_recommended,
    sortable: true,
  },
  {
    name: 'Seed Count per Scale UOM',
    selector: (row) => row.seed_cnt_per_scale_uow,
    sortable: true,
  },
  {
    name: 'Unit Size Count',
    selector: (row) => row.unit_size_cnt,
    sortable: true,
  },
  {
    name: 'Locked',
    selector: (row) => row.locked,
    sortable: true,
    cell: (row) => (row.locked ? 'True' : 'False'),
  },
  {
    name: 'Active',
    selector: (row) => row.active,
    sortable: true,
    cell: (row) => (row.active ? 'True' : 'False'),
  },
  {
    name: 'Date and Time Created',
    selector: (row) => row.date_and_time_created,
    sortable: true,
  },
];

export default mfrRecipeColumns;
