import { Card, CardContent, Grid, Typography } from '@mui/material';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Machine, MachineDeployment } from '../model';

const columns: IDataTableColumn<MachineDeployment>[] = [
  {
    name: 'Software Deployment ID',
    selector: (row) => row.machine_software_deployment_id,
    sortable: true,
  },
  {
    name: 'Software Type',
    selector: (row) => row.machine_software_type,
    sortable: true,
  },
  {
    name: 'Software Version Name',
    selector: (row) => row.machine_software_version_name,
    sortable: true,
  },
  {
    name: 'Scheduled Update Date/Time',
    selector: (row) => row.scheduled_on_machine_date_time,
    sortable: true,
    format: (row) => new Date(row.scheduled_on_machine_date_time).toLocaleString(),
    sortFunction: (a, b) =>
      new Date(a.scheduled_on_machine_date_time).getTime() - new Date(b.scheduled_on_machine_date_time).getTime(),
  },
  {
    name: 'Completed Update Date/Time',
    selector: (row) => row.completed_date_time,
    sortable: true,
    format: (row) => new Date(row.completed_date_time).toLocaleString(),
    sortFunction: (a, b) => new Date(a.completed_date_time).getTime() - new Date(b.completed_date_time).getTime(),
  },
];

export interface MachineDeploymentsProps {
  machine: Machine;
}

const MachineDeployments: React.FunctionComponent<MachineDeploymentsProps> = (props) => {
  const { machine } = props;

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Machine Deployments
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DataTable
              noHeader
              columns={columns}
              data={machine.machine_deployments}
              defaultSortAsc={false}
              striped
              hightlightOnHover
              pagination
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MachineDeployments;
