import React, { useEffect } from 'react';
import axios from '../../utils/axios.utils';

interface DatabridgeIntegration {
  [key: string]: any;
}

interface AdaptersData {
  [key: string]: any;
}

interface PartnerData {
  [key: string]: any;
}

interface DatabridgeAdminContextInterface {
  integrationsLoading?: boolean;
  integrations?: DatabridgeIntegration[];
  fetchDatabridgeIntegrations?: () => void;

  integrationDetails?: DatabridgeIntegration;
  integrationLoading?: boolean;
  fetchDatabridgeIntegration?: (databridge_integration_id: string) => void;
}

const DatabridgeAdminContext = React.createContext<DatabridgeAdminContextInterface>({});

const DatabridgeAdminContextConsumer = DatabridgeAdminContext.Consumer;

const DatabridgeAdminContextProvider: React.FC = ({ children }) => {
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
  };

  const [integrations, setIntegrations] = React.useState<DatabridgeIntegration[]>([]);
  const [integrationsLoading, setIntegrationsLoading] = React.useState(false);

  const [integrationDetails, setIntegration] = React.useState<DatabridgeIntegration>({});
  const [integrationLoading, setIntegrationLoading] = React.useState(false);

  const [adaptersData, setAdaptersData] = React.useState<AdaptersData | null>(null);
  const [selectedIntegrationId, setSelectedIntegrationId] = React.useState<string>('1');
  const [selectedIntegration, setSelectedIntegration] = React.useState<DatabridgeIntegration | null>(null);
  const [partnerData, setPartnerData] = React.useState<PartnerData | null>(null);

  const fetchDatabridgeIntegrations = () => {
    axios
      .get<string, any>('api/databridge_integrations', { headers })
      .then((response) => {
        setIntegrations(response.data.results);
      })
      .catch((error) => {
        console.error('Error fetching integrations:', error);
      });
  };

  const fetchDatabridgeIntegration = (databridge_integration_id: string) => {
    axios
      .get<string, any>(`api/databridge_integrations/${databridge_integration_id}`, { headers })
      .then((response) => {
        setIntegration(response.data.result);
      })
      .catch((error) => {
        console.error('Error fetching integrations:', error);
      });
  };

  // useEffect(() => {
  //   axios
  //     .get<string, any>('api/databridge_adapters/', { headers })
  //     .then((response) => {
  //       setAdaptersData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching adapters data:', error);
  //     });
  // }, []);

  return (
    <DatabridgeAdminContext.Provider
      value={{
        integrationsLoading,
        integrations,
        fetchDatabridgeIntegrations,

        integrationDetails,
        integrationLoading,
        fetchDatabridgeIntegration,
      }}
    >
      {children}
    </DatabridgeAdminContext.Provider>
  );
};

export { DatabridgeAdminContextProvider, DatabridgeAdminContextConsumer, DatabridgeAdminContext };
