import { IDataTableColumn } from '../../dataTable/DataTable/model';

const gtinPrefixColumns: IDataTableColumn<any>[] = [
  {
    name: 'GTIN Prefix Cloud ID',
    selector: (row) => row.autotreat_gtin_prefix_cloud_id,
    sortable: true,
  },
  {
    name: 'GTIN Prefix ID',
    selector: (row) => row.gtin_prefix_id,
    sortable: true,
  },
  {
    name: 'Prefix',
    selector: (row) => row.prefix,
    sortable: true,
  },
  {
    name: 'Company',
    selector: (row) => row.company,
    sortable: true,
  },
  {
    name: 'Description',
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: 'User Name',
    selector: (row) => row.user_name,
    sortable: true,
  },
  {
    name: 'Date and Time',
    selector: (row) => row.date_and_time,
    sortable: true,
  },
  {
    name: 'Active',
    selector: (row) => row.active,
    sortable: true,
    cell: (row) => (row.active ? 'True' : 'False'),
  },
];

export default gtinPrefixColumns;
