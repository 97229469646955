import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'AutoTreat MFR Cloud ID',
    selector: (row) => row.autotreat_mfr_cloud_id,
    sortable: true,
  },
  {
    name: 'Global MFR ID',
    selector: (row) => row.global_mfr_id,
    sortable: true,
  },
  {
    name: 'MFR Name',
    selector: (row) => row.mfr_name,
    sortable: true,
  },
  {
    name: 'MFR Enabled',
    selector: (row) => row.mfr_enabled,
    sortable: true,
    cell: (row) => (row.mfr_enabled ? 'True' : 'False'),
  },
  {
    name: 'MFR Enabled Liquid',
    selector: (row) => row.mfr_enabled_liquid,
    sortable: true,
    cell: (row) => (row.mfr_enabled_liquid ? 'True' : 'False'),
  },
  {
    name: 'MFR Enabled Recipe',
    selector: (row) => row.mfr_enabled_recipe,
    sortable: true,
    cell: (row) => (row.mfr_enabled_recipe ? 'True' : 'False'),
  },
  {
    name: 'MFR Enabled Seed',
    selector: (row) => row.mfr_enabled_seed,
    sortable: true,
    cell: (row) => (row.mfr_enabled_seed ? 'True' : 'False'),
  },
  {
    name: 'MFR Sync Set ID',
    selector: (row) => row.mfr_sync_set_id,
    sortable: true,
  },
  {
    name: 'Active',
    selector: (row) => row.active,
    sortable: true,
    cell: (row) => (row.active ? 'True' : 'False'),
  },
  {
    name: 'Date and Time Created',
    selector: (row) => row.date_and_time_created,
    sortable: true,
  },
];

export default columns;
