import axios from '../../utils/axios.utils';
import React, { useState, useContext } from 'react';
import { Button, TextField, Grid, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { ManufacturersNewContext } from '../contexts/ManufacturersNewContext';
import { useHistory } from 'react-router-dom';

const NewManufacturerForm = ({ onSuccess }) => {
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    global_mfr_id: '',
    mfr_name: '',
    mfr_enabled: false,
    mfr_enabled_liquid: false,
    mfr_enabled_recipe: false,
    mfr_enabled_seed: false,
    mfr_sync_set_id: '',
    active: true,
  });

  const { formSubmitting, formErrors } = useContext(ManufacturersNewContext);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post('api/admin/autotreat_mfr/mfrs/', formValues, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      if (onSuccess) onSuccess();
      history.push('/admin/manufacturer_management');
    } catch (error) {
      console.error('Error creating manufacturer:', error);
      alert('Failed to create manufacturer. Please try again.');
    }
  };

  return (
    <>
      <Typography
        variant="body1"
        style={{ cursor: 'pointer', color: 'darkgrey', marginBottom: '20px' }}
        onClick={() => history.push('/admin/manufacturer_management')}
      >
        ← Return to Manufacturer List
      </Typography>
      <form onSubmit={handleSubmit} style={{ maxWidth: '50%', margin: '0', textAlign: 'left' }}>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px', marginBottom: '30px' }}>
          New Manufacturer
        </Typography>
        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="global_mfr_id"
              label="Global MFR ID"
              fullWidth
              value={formValues.global_mfr_id}
              onChange={handleInputChange}
              error={!!formErrors?.global_mfr_id}
              helperText={formErrors?.global_mfr_id}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="mfr_name"
              label="MFR Name"
              fullWidth
              value={formValues.mfr_name}
              onChange={handleInputChange}
              error={!!formErrors?.mfr_name}
              helperText={formErrors?.mfr_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="mfr_sync_set_id"
              label="MFR Sync Set ID"
              fullWidth
              value={formValues.mfr_sync_set_id}
              onChange={handleInputChange}
              error={!!formErrors?.mfr_sync_set_id}
              helperText={formErrors?.mfr_sync_set_id}
            />
          </Grid>
          {['mfr_enabled', 'mfr_enabled_liquid', 'mfr_enabled_recipe', 'mfr_enabled_seed', 'active'].map((field) => (
            <Grid item xs={12} sm={6} key={field}>
              <FormControlLabel
                control={<Checkbox name={field} checked={formValues[field]} onChange={handleInputChange} />}
                label={field.replace('_', ' ').toUpperCase()}
              />
            </Grid>
          ))}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
            <Button variant="contained" color="primary" type="submit" disabled={formSubmitting}>
              Create
            </Button>
            <Button variant="contained" onClick={() => history.push('/admin/manufacturer_management')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default NewManufacturerForm;
