export const APPLICATION_URL = process.env.REACT_APP_URL;
export const AUTH_ZERO_DOMAIN = process.env.REACT_APP_AUTH_ZERO_DOMAIN;
export const AUTH_ZERO_CLIENT_ID = process.env.REACT_APP_AUTH_ZERO_CLIENT_ID;
export const AUTH_ZERO_AUDIENCE = process.env.REACT_APP_AUTH_ZERO_AUDIENCE;
export const AGSYNC_CLIENT_ID = process.env.REACT_APP_AGSYNC_CLIENT_ID;
export const AGSYNC_CLIENT_CODE = process.env.REACT_APP_AGSYNC_CLIENT_CODE;
export const AGSYNC_REDIRECT_URI = process.env.REACT_APP_AGSYNC_REDIRECT_URI;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const MACHINE_MANAGEMENT_RETRY_INTERVAL = 2000;
export const MACHINE_MANAGEMENT_TOTAL_RETRIES = 10;
export const WHITELISTED_ADMIN_DOMAINS = ['nextlinklabs.com', 'ksiedge.com'];
export const WHITELISTED_ADMIN_EMAILS = [''];
