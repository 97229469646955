import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditMfrRecipeForm from '../components/EditMfrRecipeForm';

const EditMfrRecipePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) {
      console.error('No recipe ID found in URL! Redirecting...');
      window.location.href = `${window.location.origin}/admin/manufacturer_management?activeTab=1`;
    } else {
      console.log('Editing recipe with ID:', id);
    }
  }, [id]);

  const handleSuccess = () => {
    const baseUrl = window.location.origin;
    window.location.href = `${baseUrl}/admin/manufacturer_management?activeTab=1`;
  };

  return id ? <EditMfrRecipeForm recipeId={id} onSuccess={handleSuccess} /> : null;
};

export default EditMfrRecipePage;
