import React, { createContext, useContext, useState } from 'react';
import axios from '../../utils/axios.utils';

interface MfrRecipesContextInterface {
  recipes: any[];
  recipeItems: any[];
  recipesLoading: boolean;
  recipeItemsLoading: boolean;
  fetchRecipes: () => Promise<void>;
  fetchRecipeItems: (recipeId: string) => Promise<void>;
}

// Create the context
export const MfrRecipesContext = createContext<MfrRecipesContextInterface | null>(null);

// Provider Component
export const MfrRecipesProvider: React.FC = ({ children }) => {
  const [recipes, setRecipes] = useState<any[]>([]);
  const [recipeItems, setRecipeItems] = useState<any[]>([]);
  const [recipesLoading, setRecipesLoading] = useState(false);
  const [recipeItemsLoading, setRecipeItemsLoading] = useState(false);

  const fetchRecipes = async () => {
    setRecipesLoading(true);
    try {
      const response = await axios.get<any, any>(`api/admin/autotreat_mfr/mfr_recipes/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    } finally {
      setRecipesLoading(false);
    }
  };

  const fetchRecipeItems = async (recipeId: string) => {
    setRecipeItemsLoading(true);
    try {
      const response = await axios.get<any, any>(`api/admin/autotreat_mfr/mfr_recipes/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      setRecipeItems(response.data);
    } catch (error) {
      console.error('Error fetching recipe items:', error);
    } finally {
      setRecipeItemsLoading(false);
    }
  };

  return (
    <MfrRecipesContext.Provider
      value={{
        recipes,
        recipeItems,
        recipesLoading,
        recipeItemsLoading,
        fetchRecipes,
        fetchRecipeItems,
      }}
    >
      {children}
    </MfrRecipesContext.Provider>
  );
};

// Hook for consuming the context
export const useMfrRecipesContext = (): MfrRecipesContextInterface => {
  const context = useContext(MfrRecipesContext);
  if (!context) {
    throw new Error('useMfrRecipesContext must be used within an MfrRecipesProvider');
  }
  return context;
};
