import React from 'react';
import { Box, Typography } from '@mui/material';
import NewManufacturerForm from '../components/NewManufacturerForm';

const NewManufacturerPage: React.FC = () => {
  const handleSuccess = () => {
    // Add logic to handle success, like redirecting or showing a success message
    console.log('Manufacturer created successfully');
  };

  return (
    <Box>
      <Typography variant="h1" gutterBottom>
        Create New Manufacturer
      </Typography>
      <NewManufacturerForm onSuccess={handleSuccess} />
    </Box>
  );
};

export default NewManufacturerPage;
